import store from "@/store";

import {
  VuexModule,
  Module,
  Mutation,
  getModule,
} from "vuex-module-decorators";

@Module({ dynamic: true, namespaced: true, name: "Dash", store })
class Dash extends VuexModule {
  appDrawer = false;

  get getAppDrawer(): boolean {
    return this.appDrawer;
  }
  @Mutation
  setAppDrawer(val: boolean) {
    this.appDrawer = val;
  }
}
export default getModule(Dash);
