
import { Component, Vue } from "vue-property-decorator";
import { goToPage } from "@/scripts/navigation";

@Component
export default class Footer extends Vue {
  goToHome(): void {
    goToPage("landing");
  }
  goToAbout(): void {
    goToPage("about");
  }
  goToServices(): void {
    goToPage("services");
  }
  goToApproach(): void {
    goToPage("approach");
  }
  goToContact(): void {
    goToPage("contact");
  }
}
