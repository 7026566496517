var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-layout',[_c('v-app-bar',{staticClass:"hidden-xs-and-down pa-0 ma-0",staticStyle:{"z-index":"1"},attrs:{"height":"120","extension-height":"10","extended":"","elevation":"3"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-0 ma-0",attrs:{"flat":"","tile":"","height":"100px","width":"100%","color":"white"}},[_c('v-row',{staticClass:"flex-row-reverse pa-3",attrs:{"justify":"space-around","background-color":"#10552C"}},[_c('v-col',{staticClass:"text-end text-h6",attrs:{"cols":"5"}},[_c('p',[_vm._v(" SOLVING AGRICULTURAL VALUE CHAIN PROBLEMS "),_c('br'),_vm._v("WITH TECHNOLOGIES AND PARTNERSHIPS ")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('a',{staticClass:"black--text text-decoration-none",attrs:{"href":"#"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-img',{staticClass:"mr-2 my-2",attrs:{"alt":"SSS Logo","contain":"","src":require('@/assets/AgtechLogo.png'),"max-width":"140"}})],1)])])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":"","tile":"","width":"100%","height":"40px","color":"appBar"}},[_c('v-row',{attrs:{"justify":"space-around","no-gutters":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-tabs',{attrs:{"height":"36px","background-color":"appBar","slider-color":"white","slider-size":"3","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"to":{
                    name: 'landing',
                  }}},[_vm._v(" Home ")]),_c('v-tab',{staticClass:"text-no-wrap",attrs:{"to":{
                    name: 'about',
                  }}},[_vm._v(" About ")]),_c('v-tab',{staticClass:"text-no-wrap px-10",attrs:{"to":{
                    name: 'services',
                  }}},[_vm._v(" Services ")]),_c('v-tab',{staticClass:"text-no-wrap px-10",attrs:{"to":{
                    name: 'contact',
                  }}},[_vm._v(" Contact ")])],1)],1),_c('v-col',{attrs:{"cols":"2"}})],1)],1)],1)],1)],1),_c('v-app-bar',{staticClass:"hidden-sm-and-up",attrs:{"app":"","dark":"","height":"70","color":"appBar"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-app-bar-nav-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.flipDrawer()}}})],1)],1),_c('v-row',{attrs:{"justify":"end","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('a',{attrs:{"href":"#"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-img',{attrs:{"alt":"SSS Logo","contain":"","src":require('@/assets/AgtechLogo.png'),"max-width":"50"}})],1)])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }