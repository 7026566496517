import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

//Layout
import NavDrawer from "@/components/layout/NavDrawer.vue";
import AppBar from "@/components/layout/Appbar.vue";
import Footer from "@/components/layout/Footer.vue";

//Views
import Landing from "@/views/HomeView.vue";
import About from "@/views/AboutView.vue";
import Services from "@/views/ServicesView.vue";
import Contact from "@/views/ContactView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: {
      name: "landing",
    },
  },
  {
    path: "/landing",
    name: "landing",
    components: {
      default: Landing,
      nav: NavDrawer,
      footer: Footer,
      appBar: AppBar,
    },
  },
  {
    path: "/about",
    name: "about",
    components: {
      default: About,
      nav: NavDrawer,
      footer: Footer,
      appBar: AppBar,
    },
  },
  {
    path: "/services",
    name: "services",
    components: {
      default: Services,
      nav: NavDrawer,
      footer: Footer,
      appBar: AppBar,
    },
  },
  {
    path: "/contact",
    name: "contact",
    components: {
      default: Contact,
      nav: NavDrawer,
      footer: Footer,
      appBar: AppBar,
    },
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
export default router;
