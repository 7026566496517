
import { Component, Vue } from "vue-property-decorator";
import Dash from "@/store/modules/Dash";

@Component
export default class NavDrawer extends Vue {
  flipDrawer(val: boolean): void {
    // console.log("Flip drawer to: ", !this.appDrawer);
    Dash.setAppDrawer(val);
  }
  get appDrawer(): boolean {
    return Dash.getAppDrawer;
  }
}
