
import { Component, Vue } from "vue-property-decorator";
import { goToPage } from "@/scripts/navigation";
import Dash from "@/store/modules/Dash";

@Component
export default class AppBar extends Vue {
  tab = this.$route.name;
  flipDrawer(): void {
    // console.log("Flip drawer to: ", !this.appDrawer);
    Dash.setAppDrawer(!this.appDrawer);
  }
  get appDrawer(): boolean {
    return Dash.getAppDrawer;
  }

  goToHome(): void {
    goToPage("landing");
  }
  goToAbout(): void {
    goToPage("about");
  }
  goToServices(): void {
    goToPage("services");
  }
  goToContact(): void {
    goToPage("contact");
  }
}
